<template>
  <div class="grid md:grid-cols-4 xs:grid-cols-1 mb-8 relative z-10" style="max-height: 416px">
    <div class="Home md:col-span-3 sm:col-span-4" :key="`banner-${banners.length}`">
      <agile
        :fade="true"
        :autoplay="true"
        :initialSlide="0"
        :slideToShow="1"
        ref="thumbnails"
        :autoplaySpeed="12000"
        :speed="1000"
      >
        <img
          class="slide"
          v-for="b in banners"
          :key="b.id"
          :src="b.src"
          :class="b.action ? 'cursor-pointer' : ''"
          @click="openAction(b.action)"
        />

        <template slot="prevButton">
          <svg
            width="16"
            height="28"
            viewBox="0 0 16 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style="margin: 0 auto"
          >
            <path
              d="M14.5249 27L2.51545 15.5364C1.69005 14.7486 1.69005 13.4309 2.51545 12.643L14.5249 1.17944"
              stroke="#E2E2E2"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        </template>
        <template slot="nextButton">
          <svg
            width="15"
            height="28"
            viewBox="0 0 15 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style="margin: 0 auto"
          >
            <path
              d="M1 1L13.0094 12.4636C13.8348 13.2514 13.8348 14.5691 13.0094 15.357L1 26.8206"
              stroke="#E2E2E2"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        </template>
      </agile>
    </div>

    <div class="ml-4 xs:hidden md:block">
      <my-scroll :ops="ops" ref="vs">
        <img
          :src="n.src"
          class="mb-2 rounded-lg cursor-pointer"
          @click="$refs.thumbnails.goTo(idx)"
          v-for="(n, idx) in banners"
          :key="idx"
          :class="`slide--${n.order}`"
        />
      </my-scroll>
    </div>
  </div>
</template>

<script>
import { VueAgile } from 'vue-agile'

export default {
  name: 'home-caraousel',
  components: {
    agile: VueAgile
  },
  props: ['banners'],
  data() {
    return {
      lol: 5,
      ops: {
        vuescroll: {
          mode: 'native',
          sizeStrategy: 'number',
          detectResize: true,
          locking: true
        },
        scrollPanel: {
          scrollingX: false,
          scrollingY: true,
          verticalNativeBarPos: 'right',
          maxHeight: 416
        },
        bar: {
          background: '#176FA9',
          opacity: 1,
          size: '5px',
          minSize: 0.2
        }
      }
    }
  },
  methods: {
    openAction(val) {
      if (val) {
        window.open(val, '_blank')
      }
    }
  }
}
</script>
