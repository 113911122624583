<template>
  <div class="Default min-h-screen drawer drawer-end w-full">
    <input id="my-drawer" type="checkbox" class="drawer-toggle" />
    <div class="drawer-content flex flex-col h-screen">
      <header>
        <div class="navTop">
          <div class="navbar layoutDefault">
            <div class="flex-1">
              <img :src="icareLogo" class="mt-0.6" />
            </div>
            <div class="flex-none hidden px-2 mx-2 sm:flex">
              <div class="flex items-stretch">
                <button
                  class="btn btn-ghost btn-sm rounded-btn capitalize hover:border-transparent"
                  @click="$router.push({ name: 'home' })"
                >
                  Home
                </button>
                <button
                  class="btn btn-ghost btn-sm rounded-btn capitalize hover:border-transparent"
                  @click="$router.push({ name: 'contact' })"
                >
                  Contact
                </button>
                <button
                  class="btn btn-ghost btn-sm rounded-btn capitalize hover:border-transparent"
                  @click="toTickets('closed')"
                >
                  History
                </button>
                <button
                  class="btn btn-ghost btn-sm rounded-btn capitalize hover:border-transparent"
                  @click="$router.push({ name: 'helpme' })"
                >
                  Help Me
                </button>
              </div>
            </div>
            <div class="flex-none" v-if="totalPoint">
              <div
                class="bg-hblue6 pl-2.5 h-8.33 flex items-center justify-between space-x-1 relative text-sm rounded-17px text-white font-Roboto tracking-wide cursor-pointer"
                @click="
                  $router.push({ name: 'points', query: { limit: 10, offset: 0, detail: 1 } })
                "
              >
                <div>Point</div>
                <div
                  class="h-8.33 px-1 rounded-17px bg-hblue7 text-sm flex items-center justify-center"
                >
                  <span class="text-hblue8 font-Roboto font-semibold">
                    {{ totalPoint }}
                  </span>
                </div>
              </div>
            </div>

            <div class="flex-none">
              <button
                class="btn btn-circle btn-ghost sm:mr-3 xs:mr-1 relative hover:border-transparent"
                @click="$router.push({ name: 'broadcasts' })"
              >
                <svg
                  width="31"
                  height="32"
                  viewBox="0 0 31 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="15.2842" cy="16.1972" r="15.2842" fill="#C8C8C8" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.6297 11.8568C9.69891 11.7607 9.81306 11.6948 9.94493 11.6948H21.0311C21.163 11.6948 21.2771 11.7607 21.3463 11.8568L15.488 15.9921L9.6297 11.8568ZM7.55916 12.3114C7.55905 12.32 7.55905 12.3286 7.55916 12.3371V20.3239C7.55916 21.6397 8.63745 22.6995 9.94493 22.6995H21.0311C22.3386 22.6995 23.4169 21.6397 23.4169 20.3239V12.3371C23.417 12.3286 23.417 12.32 23.4169 12.3115V12.0704C23.4169 10.7546 22.3386 9.69482 21.0311 9.69482H9.94493C8.63745 9.69482 7.55916 10.7546 7.55916 12.0704V12.3114ZM21.4169 14.2551V20.3239C21.4169 20.5212 21.2479 20.6995 21.0311 20.6995H9.94493C9.72807 20.6995 9.55916 20.5212 9.55916 20.3239V14.2551L14.9113 18.0331C15.2571 18.2771 15.719 18.2771 16.0647 18.0331L21.4169 14.2551Z"
                    fill="white"
                  />
                </svg>
                <div
                  v-if="broadcast.count > 0"
                  class="border-white top-1 right-1 rounded-full bg-hred h-4.1 w-4.1 font-bold text-center text-white"
                  style="position: absolute; font-size: 10px; border-width: 2px"
                >
                  {{ broadcast.count }}
                </div>
              </button>
            </div>
            <div class="flex-none xs:hidden sm:flex">
              <div class="dropdown dropdown-end">
                <div
                  tabindex="0"
                  class="h-9 btn-primary text-white btn text-xs capitalize font-medium relative z-100 border-0 hover:bg-hblue"
                  style="border-radius: 42px; max-width: 160px"
                >
                  <span>{{ user.name }}</span>
                  <SvgArrowDown
                    class="ml-2 stroke-current text-white h-3.4 w-3.5 stroke-2 transform -rotate-90"
                  />
                </div>
                <ul
                  tabindex="0"
                  class="p-2 shadow menu dropdown-content bg-base-100 rounded-box z-10 relative"
                  style="width: 129px; margin-top: -28px; padding-top: 32px"
                >
                  <li>
                    <button
                      class="btn btn-ghost text-sm font-medium capitalize hover:border-transparent"
                      style="padding: 0.2rem 1rem; height: 38px"
                      @click="toProfile"
                    >
                      Profile
                    </button>
                  </li>
                  <li>
                    <button
                      class="btn btn-ghost text-sm font-medium capitalize hover:border-transparent"
                      style="padding: 0.2rem 1rem; height: 38px"
                      @click="$router.push({ name: 'notifications' })"
                    >
                      Notifications
                    </button>
                  </li>
                  <li>
                    <button
                      class="btn btn-ghost text-sm font-medium capitalize hover:border-transparent"
                      @click="toLogout"
                      style="padding: 0.2rem 1rem; height: 38px"
                    >
                      Logout
                    </button>
                  </li>
                </ul>
              </div>
            </div>

            <div class="flex-none xs:flex sm:hidden">
              <label for="my-drawer" class="drawer-button">
                <div class="btn btn-circle btn-ghost"><IconDrawer class="h-6 w-6" /></div>
              </label>
            </div>
          </div>
        </div>
      </header>

      <div class="relative">
        <img class="absolute top-32 right-0" :src="group308" />
      </div>

      <main class="px-2 flex-grow">
        <div
          class="layoutDefault px-2"
          :style="
            $route.meta.group.includes('customer')
              ? 'margin-top: 72px; padding-top: 24px'
              : 'margin-top: 86px; padding-top: 0px'
          "
        >
          <home-carousel :banners.sync="banners" v-if="$route.meta.group.includes('banner')" />

          <div
            class="grid xs:grid-cols-1 md:grid-cols-2 gap-4"
            v-if="$route.meta.group.includes('user')"
          >
            <div class="z-10 md:col-span-1">
              <div class="text-lg font-normal">Hello,</div>
              <div class="font-semibold mb-4 text-4xl text-htealdark">{{ user.name }}</div>
              <div class="flex">
                <div class="mr-4 pt-1">
                  <SvgCustomer class="h-5 w-5" />
                </div>

                <div>
                  <span class="text-black text-lg font-semibold">
                    {{ user.customer ? user.customer.data.name : '-' }}
                  </span>
                </div>
              </div>
              <div class="flex mt-2">
                <div class="mr-4 pt-2">
                  <SvgAddress class="h-5 w-5" />
                </div>

                <div>
                  <p class="text-black text-13px font-medium">
                    {{ user.customer ? user.customer.data.address : '-' }}
                  </p>
                </div>
              </div>
            </div>

            <div class="z-10 md:col-span-1">
              <div class="flex justify-between">
                <div
                  class="relative w-26 h-30.5 px-2.5 py-2 bg-white z-10 cursor-pointer"
                  style="box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25); border-radius: 3px"
                  v-for="c in homeCount"
                  :key="c.status"
                  @click="toTickets(c.status)"
                >
                  <div
                    class="absolute bg-hblue hidden sm:block"
                    style="
                      right: -9px;
                      top: 17px;
                      border-radius: 0 3px 3px 0;
                      width: 9px;
                      height: 88px;
                    "
                  ></div>
                  <p
                    class="w-24 h-10 text-xs font-semibold text-htext capitalize"
                    style="line-height: 15px"
                  >
                    Ticket {{ c.status }}
                  </p>
                  <div class="w-full h-0" style="border: 0.5px solid #176fa9"></div>

                  <p class="text-4xl text-htext text-center mt-3 font-Roboto">{{ c.count }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <router-view></router-view>
      </main>

      <nav-footer style="min-height: 179px" />
    </div>
    <div class="drawer-side">
      <label for="my-drawer" class="drawer-overlay"></label>
      <ul class="menu overflow-y-auto w-68 bg-base-100 text-base-content">
        <!-- Sidebar content here -->
        <li class="p-4">
          <div class="flex justify-between mb-3">
            <span class="text-black text-lg font-semibold">
              {{ user.customer ? user.customer.data.name : 'Error' }}
            </span>

            <label for="my-drawer" class="drawer-button flex-none"
              ><IconClose class="mt-1.5"
            /></label>
          </div>
        </li>
        <li style="border-bottom: 0.5px solid #a7a7a7">
          <button
            class="btn btn-ghost capitalize font-medium p-4 rounded-none hover:border-transparent"
            @click="$router.push({ name: 'home' })"
          >
            <label for="my-drawer" class="text-center w-full">Home</label>
          </button>
        </li>
        <li style="border-bottom: 0.5px solid #a7a7a7">
          <button
            class="btn btn-ghost capitalize font-medium p-4 rounded-none hover:border-transparent"
            @click="$router.push({ name: 'contact' })"
          >
            <label for="my-drawer" class="text-center w-full">Contact</label>
          </button>
        </li>
        <li style="border-bottom: 0.5px solid #a7a7a7">
          <button
            class="btn btn-ghost capitalize font-medium p-4 rounded-none hover:border-transparent"
            @click="toTickets('closed')"
          >
            <label for="my-drawer" class="text-center w-full"> History</label>
          </button>
        </li>
        <li style="border-bottom: 0.5px solid #a7a7a7">
          <button
            class="btn btn-ghost capitalize font-medium p-4 rounded-none hover:border-transparent"
            @click="$router.push({ name: 'notifications' })"
          >
            <label for="my-drawer" class="text-center w-full"> Notifications</label>
          </button>
        </li>
        <li style="border-bottom: 0.5px solid #a7a7a7">
          <button
            for="my-drawer"
            class="btn btn-ghost capitalize font-medium p-4 rounded-none hover:border-transparent"
            @click="toProfile"
          >
            <label for="my-drawer" class="text-center w-full"> Profile </label>
          </button>
        </li>
        <li style="border-bottom: 0.5px solid #a7a7a7">
          <button
            class="btn btn-ghost capitalize font-medium p-4 rounded-none hover:border-transparent"
            @click="$router.push({ name: 'helpme' })"
          >
            <label for="my-drawer" class="text-center w-full">Help Me</label>
          </button>
        </li>

        <div class="mt-12 text-center px-3">
          <label for="my-drawer" class="text-center">
            <button
              class="btn btn-outline capitalize border btn-error w-full rounded-full"
              @click="toLogout"
            >
              <label for="my-drawer" class="text-center w-full">
                <span class="text-herror"> Logout </span>
              </label>
            </button>
          </label>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import HomeCarousel from '@/components/HomeCarousel.vue'

import icareLogo from '@/assets/images/tagline-black.png'
import group308 from '@/assets/images/group308.png'

import SvgCustomer from '@/assets/icons/icon-customer.svg?inline'
import SvgAddress from '@/assets/icons/icon-address.svg?inline'
import SvgArrowDown from '@/assets/icons/icon-arrow.svg?inline'
import IconClose from '@/assets/icons/icon-close.svg?inline'
import IconDrawer from '@/assets/icons/menu-top.svg?inline'

import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'Default',
  components: {
    HomeCarousel,
    SvgCustomer,
    SvgAddress,
    SvgArrowDown,
    IconClose,
    IconDrawer,
    NavFooter: () => import('@/components/footer')
  },
  data() {
    return {
      icareLogo,
      group308,
      HomeCarousel
    }
  },
  mounted() {
    this.checker()
    this.GET_BROADCAST_COUNT()
    this.GET_TICKET_COUNT()
    this.GET_BANNERS()
    this.TOTAL_POINTS()
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      fcm: state => state.auth.fcm,
      broadcast: state => state.notif.broadcast,
      banners: state => state.banner.banners,
      totalPoint: state => state.notif.point
    }),
    ...mapGetters('ticket', ['homeCount']),
    ...mapGetters('notif', ['listNotification'])
  },
  methods: {
    ...mapActions('auth', ['ME', 'LOGOUT', 'DELETE_FCM']),
    ...mapActions('notif', ['GET_BROADCAST_COUNT', 'TOTAL_POINTS']),
    ...mapActions('ticket', ['GET_TICKET_COUNT', 'GET_TICKET_TYPES']),
    ...mapActions('banner', ['GET_BANNERS']),
    ...mapActions('instrument', ['GET_CAF', 'DETAIL_CAF']),
    ...mapActions('modal', ['MODAL_CAF_POP']),
    async toLogout() {
      await this.DELETE_FCM({ token: this.fcm })
      await this.LOGOUT()
      this.$router.replace({ name: 'login' })
    },
    toProfile() {
      let customer = this.user.customer ? this.user.customer.data : null

      let removeSpacing = customer.name ? customer.name.replaceAll(/\s+/g, '-') : ''
      let removeSlash = removeSpacing.replaceAll('/', '')
      this.$router.push({ name: 'profile', params: { slug: removeSlash } })
    },
    toTickets(val) {
      this.$router.push({
        name: 'ticket.index',
        query: { status: val, offset: 0, limit: 9, ticket_type: 0 }
      })
    },
    async checker() {
      let me = await this.ME()
      this.GET_TICKET_TYPES()

      if (me) {
        let res = await this.GET_CAF({ status: 'created,Revision', carousel: 1 })
        if (Array.isArray(res.data) && res.data.length) {
          //this.DETAIL_CAF(res.data[0].id)
          this.MODAL_CAF_POP(true)
        }
      }
      // else {
      //   res = await this.GET_CAF({ status: 'readed' })
      //   if (Array.isArray(res.data) && res.data.length) this.DETAIL_CAF(res.data[0].id)
      // }
    }
  }
}
</script>
